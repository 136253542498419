<template>
  <div class="survey-content">
    <h3 class="block-title">Опрос завершён :)</h3>
    <div class="text">
      <p>
        <b>Выбранные курсы ты сможешь найти в своём
          <router-link to="/profile">
            Личном кабинете
          </router-link>
        .</b>
      </p>
      <p>Помни, что их нужно пройти в текущем месяце. После каждого курса есть возможность оставить обратную связь —
        будем ей очень рады.</p>
    </div>
    <router-link to="/profile" class="button-color lg">Личный кабинет</router-link>
  </div>
</template>

<script>

export default {
  name: 'SurveysFinal',

  props: {},

  data() {
    return {};
  },

  mounted() {
  },

  watch: {},

  methods: {},

  directives: {},

  components: {},
};
</script>

<style lang="scss" scoped>
</style>