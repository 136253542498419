<template>
  <div>
    <div class="survey-section" v-if="survey.courses && survey.courses.length">
      <h3 class="block-title">Уже выбрали за тебя :)</h3>
      <div class="checkbox-item">
        <label v-for="course in survey.courses" :key="course.id" class="checkbox-circle inactive">
          <input type="checkbox" checked>
          <span>{{ course.name }}</span>
        </label>
      </div>
    </div>
    <div class="survey-section">
      <h3 class="block-title">Выбери
        {{ survey.additional_count }}
        {{ getNoun(survey.additional_count, 'дополнительный курс', 'дополнительных курса', 'дополнительных курсов') }}
      </h3>
      <div class="faq-group">
        <AdditionalCourseSlide v-for="(course, index) in survey.picked_courses"
                               :key="index"
                               :theme="course.theme"
                               :courses="course.courses"
                               :selectedCourses="selectedCourses"
                               :isSelectionCompleted="isSelectionCompleted"
                               @selectCourse="selectCourse"
                               />
      </div>
    </div>
    <div class="survey-footer-wrap">
      <div class="survey-footer" :class="{'active': isSelectionCompleted}">
        <div class="content">
          <div class="survey-footer-inner">
            <a :class="{'disabled': !isSelectionCompleted}"
               @click="completeStep"
               class="button-color lg">
              Далее
            </a>
            <div class="progress-wrap">
              <div class="progress-title">
                Выбрано: {{ selectedCourses.length }} из {{ survey.additional_count }}
              </div>
              <div class="progress-item">
                <div class="progress-line"
                     :style="{width: (selectedCourses.length / survey.additional_count  * 100) + '%' }">
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Survey from '@/api/survey';
import AdditionalCourseSlide from '@/components/surveys/components/AdditionalCourseSlide';
import {getNoun} from '@/core/helpers/getNoun';

export default {
  name: 'TopicsSelect',

  props: {
    survey: Object,
    surveyResult: Object,
  },

  data() {
    return {
      list: null,
      selectedCourses: [],
    };
  },

  async created() {

  },

  computed: {
    isSelectionCompleted() {
      return this.selectedCourses.length === this.survey.additional_count;
    },
  },

  mounted() {
  },

  watch: {},

  methods: {
    getNoun,

    selectCourse(value) {
      if(this.selectedCourses.includes(+value)){
        this.selectedCourses = this.selectedCourses.filter(item => item !== value);
      } else {
        this.selectedCourses.push(value);
      }
    },

    async completeStep() {
      const data = {
        picked_additional_courses: this.selectedCourses,
      };
      await Survey.setResult(this.survey.result, data);
      this.$emit('nextPage');
    },
  },

  directives: {},

  components: {
    AdditionalCourseSlide,
  },
};
</script>

<style lang="scss" scoped>
</style>