<template>
  <div class="faq-item toggle-block" :class="{'active' : isOpened}">
    <div class="faq-title toggle-button" @click="isOpened = !isOpened">
      {{ theme }}
    </div>
    <VueSlideToggle :open="isOpened">
      <div class="toggle-content" style="display: block">
        <div v-if="courses" class="faq-content">
          <div v-for="course in courses" :key="course.id" class="checkbox-item">
            <label class="checkbox-circle">
              <input :disabled="isSelectionCompleted && !selectedCourses.includes(course.id)"
                     :checked="selectedCourses.includes(course.id)"
                     :value="course.id"
                     @input="$emit('selectCourse', course.id)"
                     type="checkbox">
              <span>{{ course.name }}</span>
            </label>
            <div v-if="course.tag" class="marker-item hollow"> {{ course.tag }} </div>
          </div>
        </div>
      </div>
    </VueSlideToggle>
  </div>
</template>

<script>
import {VueSlideToggle} from 'vue-slide-toggle';
import Checkbox from '../../ui/Checkbox';

export default {
  name: 'AdditionalCourseSlide',

  props: {
    theme: String,
    courses: Array,
    selectedCourses: Array,
    isSelectionCompleted: Boolean,
  },

  data() {
    return {
      isOpened: false,
    };
  },

  mounted() {},

  methods: {
    select(value) {
      this.$emit('input', value);
    },
  },

  components: {
    VueSlideToggle,
    Checkbox,
  },
};
</script>

<style scoped>

</style>