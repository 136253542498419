<template>
  <div>
    <div class="survey-section">
      <h3 class="block-title">{{ survey.text_question_rating }}</h3>
      <div class="scale-10-group">
        <div class="grid">
          <div class="box">
            <div class="scale-10-item">
              <label class="checkbox-circle">
                <input type="radio" name="scale-10" value="1" v-model="picked">
                <span>1</span>
              </label>
            </div>
          </div>
          <div class="box">
            <div class="scale-10-item">
              <label class="checkbox-circle">
                <input type="radio" name="scale-10" value="2" v-model="picked">
                <span>2</span>
              </label>
            </div>
          </div>
          <div class="box">
            <div class="scale-10-item">
              <label class="checkbox-circle">
                <input type="radio" name="scale-10" value="3" v-model="picked">
                <span>3</span>
              </label>
            </div>
          </div>
          <div class="box">
            <div class="scale-10-item">
              <label class="checkbox-circle">
                <input type="radio" name="scale-10" value="4" v-model="picked">
                <span>4</span>
              </label>
            </div>
          </div>
          <div class="box">
            <div class="scale-10-item">
              <label class="checkbox-circle">
                <input type="radio" name="scale-10" value="5" v-model="picked">
                <span>5</span>
              </label>
            </div>
          </div>
          <div class="box">
            <div class="scale-10-item">
              <label class="checkbox-circle">
                <input type="radio" name="scale-10" value="6" v-model="picked">
                <span>6</span>
              </label>
            </div>
          </div>
          <div class="box">
            <div class="scale-10-item">
              <label class="checkbox-circle">
                <input type="radio" name="scale-10" value="7" v-model="picked">
                <span>7</span>
              </label>
            </div>
          </div>
          <div class="box">
            <div class="scale-10-item">
              <label class="checkbox-circle">
                <input type="radio" name="scale-10" value="8" v-model="picked">
                <span>8</span>
              </label>
            </div>
          </div>
          <div class="box">
            <div class="scale-10-item">
              <label class="checkbox-circle">
                <input type="radio" name="scale-10" value="9" v-model="picked">
                <span>9</span>
              </label>
            </div>
          </div>
          <div class="box">
            <div class="scale-10-item">
              <label class="checkbox-circle">
                <input type="radio" name="scale-10" value="10" v-model="picked">
                <span>10</span>
              </label>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="survey-footer-wrap">
      <div class="survey-footer">
        <div class="content">
          <div class="survey-footer-inner">
            <a :class="{'disabled': !picked}"
               @click="completeStep"
               class="button-color lg">
              Далее
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import Survey from '@/api/survey';

export default {
  name: 'LearningAssessment',

  props: {
    survey: Object,
    surveyResult: Object,
  },

  data() {
    return {
      picked: null,
    };
  },

  mounted() {
  },

  watch: {},

  methods: {
    async completeStep() {
      const data = {
        learning_gratitude: this.picked,
      };
      await Survey.setResult(this.survey.result, data);
      this.$emit('nextPage');
    },
  },

  directives: {
  },

  components: {},
};
</script>

<style lang="scss" scoped>
</style>