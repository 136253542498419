<template>
  <main class="body">

    <section v-if="survey" class="section">
      <div class="content">
        <ul class="breadcrumbs">
          <li>
            <router-link to="/">Главная</router-link>
          </li>
          <li><span>{{ survey.title }}</span></li>
        </ul>
        <div>
          <template v-if="step_priority !== 5">
            <h1 class="page-title">{{ survey.title }}</h1>
            <div class="survey-section">
              <div class="progress-title">Шаг {{ stepNumber }} из {{ countOfSteps }}</div>
              <div class="progress-item">
                <div class="progress-line" :style="{width: (stepNumber / countOfSteps  * 100 - 25) + '%' }"></div>
              </div>
            </div>
          </template>
        </div>
        <transition name="fade" mode="out-in">
          <TopicsSelect @nextPage="nextPage()"
                        :survey="survey"
                        :surveyResult="surveyResult"
                        v-if="questionType === 'step_priority_courses'"/>
          <LearningAssessment @nextPage="nextPage()"
                              :survey="survey"
                              :surveyResult="surveyResult"
                              v-else-if="questionType === 'step_priority_rating'"/>
          <StatementsAssessment @nextPage="nextPage()"
                                :survey="survey"
                                :surveyResult="surveyResult"
                                v-else-if="questionType === 'step_priority_choice'"/>
          <LearningFeedback @nextPage="nextPage()"
                            :survey="survey"
                            :surveyResult="surveyResult"
                            v-else-if="questionType === 'step_priority_respond'"/>
          <SurveysFinal v-else-if="questionType === 'step_complete'"/>
        </transition>
      </div>
    </section>

    <div v-if="isConfirmModal" class="modal" id="modal-are-you-really">
      <div class="modal-container">
        <div class="modal-inner">
          <div class="modal-body">
            <div class="modal-close" @click="isConfirmModal = false"></div>
            <div class="modal-content">

              <h4 class="modal-title sm">Подтвердите действие на сайте</h4>

              <div class="text md">
                <p>Уверены, что хотите выйти? <br>Весь прогресс этого опроса будет потерян.</p>
              </div>
              <br>
              <br>
              <div class="button-group">
                <button class="button-color" @click="$router.go(-1)">Да</button>
                <button class="button-empty" @click="isConfirmModal = false">Нет</button>
              </div>

            </div>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import Survey from '@/api/survey';
import TopicsSelect from '@/components/surveys/TopicsSelect';
import LearningAssessment from '@/components/surveys/LearningAssessment';
import StatementsAssessment from '@/components/surveys/StatementsAssessment';
import LearningFeedback from '@/components/surveys/LearningFeedback';
import SurveysFinal from '@/components/surveys/SurveysFinal';

export default {
  name: 'Surveys',

  data() {
    return {
      survey: null,
      step_priority: 1,
      stepNumber: 1,
      surveyResult: null,
      isConfirmModal: false,
      countOfSteps: null,
      questionType: null,
    };
  },

  async created() {
    await this.getSurvey();
    await this.getSurveyResult();
  },

  methods: {
    async getSurvey() {
      const surveyId = Number(this.$route.params.slug.split('-').pop());
      const response = await Survey.getSurvey(surveyId);
      if (response.status === 200) {
        this.countOfSteps = this.getCountOfSteps(response.data);
        this.survey = response.data;
        this.questionType = this.getQuestionType();
        if (!this.questionType) this.nextPage();
      } else {
        await this.$router.replace('/');
      }
    },

    getCountOfSteps(survey) {
      let countOfSteps = 1;
      if (survey.step_activity_choice) countOfSteps++;
      if (survey.step_activity_rating) countOfSteps++;
      if (survey.step_activity_respond) countOfSteps++;

      return countOfSteps;
    },

    async getSurveyResult() {
      const surveyId = this.survey.result;
      const response = await Survey.getResult(surveyId);
      this.surveyResult = response.data;
    },

    nextPage() {
      this.step_priority++;
      this.questionType = this.getQuestionType();
      if (this.questionType === 'step_complete') {
        Survey.setComplete(this.survey.result);
      } else {
        this.questionType ? this.stepNumber++ : this.nextPage();
      }
    },

    getQuestionType() {
      if (this.step_priority === 5) {
        return 'step_complete';
      }
      if (this.step_priority === this.survey.step_priority_courses) {
        return 'step_priority_courses';
      }
      if ((this.step_priority === this.survey.step_priority_rating)
          && this.survey.step_activity_rating) {
        return 'step_priority_rating';
      }
      if ((this.step_priority === this.survey.step_priority_choice)
          && this.survey.step_activity_choice) {
        return 'step_priority_choice';
      }
      if ((this.step_priority === this.survey.step_priority_respond)
          && this.survey.step_activity_respond) {
        return 'step_priority_respond';
      }
      return null;
    },
  },

  beforeRouteLeave(to, from, next) {
    if (+this.step_priority < 5 && +this.step_priority !== 1 && !this.isConfirmModal) {
      this.isConfirmModal = true;
      next(false);
    } else {
      next();
    }
  },

  directives: {},

  components: {
    TopicsSelect,
    LearningAssessment,
    StatementsAssessment,
    LearningFeedback,
    SurveysFinal,
  },
};
</script>

<style lang="scss" scoped>
.fade-enter-active, .fade-leave-active {
  transition: opacity .3s ease;
}

.fade-enter, .fade-leave-to {
  opacity: 0;
}
</style>

