<template>
  <div v-if="survey">
    <div class="survey-section">
      <img :src="survey.question_image" alt="" class="survey-pic">
      <h3 class="block-title">{{ survey.question }}</h3>
      <Checkbox v-for="(answer, index) in survey.answers"
                :key="index"
                :theme="answer.text"
                @change="select(answer.id)"/>
    </div>
    <div class="survey-footer-wrap">
      <div class="survey-footer">
        <div class="content">
          <div class="survey-footer-inner">
            <a :class="{'disabled': checked && checked.length < 1}"
                @click="completeStep"
                class="button-color lg">
              Далее
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Checkbox from '../ui/Checkbox';
import Survey from '@/api/survey';

export default {
  name: 'StatementsAssessment',

  props: {
    survey: Object,
    surveyResult: Object,
  },

  data() {
    return {
      checked: [],
    };
  },

  mounted() {
  },

  watch: {},

  methods: {
    select(value) {
      if(this.checked.includes(+value)){
        this.checked = this.checked.filter(item => item !== value);
      } else {
        this.checked.push(value);
      }
    },

    async completeStep() {
      const data = {
        answers: this.checked,
      };
      await Survey.setResult(this.survey.result, data);
      this.$emit('nextPage');
    },
  },

  directives: {
  },

  components: {
    Checkbox,
  },
};
</script>

<style lang="scss" scoped>
</style>