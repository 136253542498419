<template>
  <div>
    <div class="survey-section">
      <h3 class="block-title">{{ survey.text_question_respond }}</h3>
      <textarea rows="7"
                class="field-simple-value"
                :placeholder="survey.text_question_respond"
                v-model="feedback">
      </textarea>
    </div>
    <div class="survey-footer-wrap">
      <div class="survey-footer">
        <div class="content">
          <div class="survey-footer-inner">
            <a @click="completeStep"
               :class="{'disabled': !feedback}"
               class="button-color lg">
              Далее
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Survey from '@/api/survey';

export default {
  name: 'LearningFeedback',

  props: {
    survey: Object,
    surveyResult: Object,
  },

  data() {
    return {
      feedback: null,
    };
  },

  mounted() {
  },

  watch: {},

  methods: {
    async completeStep() {
      const data = {
        feedback: this.feedback,
      };
      await Survey.setResult(this.survey.result, data);
      this.$emit('nextPage');
    },
  },

  directives: {},

  components: {},
};
</script>

<style lang="scss" scoped>
</style>